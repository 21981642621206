<!--  -->
<template>
    <!-- <blur-overlay class="flex-center" zIndex="300">
            <div class="chapter-result-box">
                <confirmPanel>
                    <div class='page-task-result dia flex-center'>
                        <div class="result-body light-dark-color">
                            <div class="result-item head" :class="{'c1': fullMark, 'c2': !fullMark}">
                            </div>

                            <div class="result-item b">
                                <div class="b-normal flex-center" v-if="!fullMark">
                                    <div class="b-item a">
                                        <img :src="`${PARAMS.assetsUrl}/img/v2_0/task/result/c3.png`" class="ex-icon rt">
                                        <span class="text-normal-aa bold text-brown-color">答对{{correctNum}}题</span>
                                    </div>
                                    <div class="b-item b">
                                        <img :src="`${PARAMS.assetsUrl}/img/v2_0/task/result/c4.png`" class="ex-icon wr">
                                        <span class="text-normal-aa bold text-brown-color">答错{{wrongNum}}题</span>
                                    </div>
                                </div>
                                <div class="b-full flex-center" v-else>
                                    <div class="b-item">
                                        <img :src="`${PARAMS.assetsUrl}/img/v2_0/task/result/c3.png`" class="ex-icon rt">
                                        <span class="text-normal-aa bold text-brown-color">{{"满分全对！再接再厉哦~"}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="result-item c">
                                <confirmPlay class="bcg" v-for="(v, i) in funListCul" :key="i" :disabled="v.isDisabled" :type="v.type" @click="funHand(v)">
                                    <span class="text-normal-ab btn bold">{{v.title}}</span>
                                </confirmPlay>
                            </div>
                        </div>
                    </div>
                </confirmPanel>
            </div>
        </blur-overlay> -->
        <div class="com-task-result">
            <confirmPro ref="confirm">
                <div class='page-task-result dia flex-center'>
                    <div class="result-body light-dark-color">
                        <div class="result-item head" :class="{'c1': fullMark, 'c2': !fullMark}">
                        </div>

                        <div class="result-item b">
                            <div class="b-normal flex-center" v-if="!fullMark">
                                <div class="b-item a">
                                    <img :src="`${PARAMS.assetsUrl}/img/v2_0/task/result/c3.png`" class="ex-icon rt">
                                    <span class="text-normal-aa bold text-brown-color">答对{{correctNum}}题</span>
                                </div>
                                <!-- <div class="b-item-line">
                                    <div class="line"></div>
                                </div> -->
                                <div class="b-item b">
                                    <img :src="`${PARAMS.assetsUrl}/img/v2_0/task/result/c4.png`" class="ex-icon wr">
                                    <span class="text-normal-aa bold text-brown-color">答错{{wrongNum}}题</span>
                                </div>
                            </div>
                            <div class="b-full flex-center" v-else>
                                <div class="b-item">
                                    <img :src="`${PARAMS.assetsUrl}/img/v2_0/task/result/c3.png`" class="ex-icon rt">
                                    <span class="text-normal-aa bold text-brown-color">{{"满分全对！再接再厉哦~"}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="result-item c">
                            <confirmPlay class="bcg" v-for="(v, i) in funListCul" :key="i" :disabled="v.isDisabled" :type="v.type" @click="funHand(v)">
                                <span class="text-normal-ab btn bold">{{v.title}}</span>
                            </confirmPlay>
                        </div>
                    </div>
                </div>
            </confirmPro>
        </div>
</template>

<script>
    import apiClass from '@/API/page/task'

    export default {
        name: '',
        props: {
            exerciseGroup: [Number, String]
            // chapterId: Number,
        },
        data() {
            return {
                api: new apiClass(),
                chapterData: {},
                funList: [
                    {title: "再做一遍", fun: 'restart', type: 'success'},
                    {title: "错题巩固", fun: 'gotoMistakes'},
                ],
                chapterId: null
            };
        },
        methods: {
            open(opt){
                let {chapterId} = opt || {}
                if(!chapterId) return
                this.chapterId = chapterId
                this.chapterData = {}
                this.dataLoad()
                this.$refs['confirm'].open({
                    showClose: true,
                    confirmType: 'c8'
                })
            },
            async dataLoad(){
                await this.infoLoad()
            },
            async infoLoad(){
                let {chapterId: chapter_id, usercode: user_code, exerciseGroup: exercise_group} = this
                let res = await this.api.chapterExStatus({chapter_id, user_code, exercise_group}) 
                if(res) this.chapterData = res
            },
            funHand(val){
                let {fun} = val || {}
                if(!this[fun]) return
                this[fun](val)
            },
            restart(){
                let {chapterId} = this
                let name = 'task-action'
                let type = 'push'
                let params = {chapterId}
                params = {routeData: this._common.encodeData(params)}
                this._routeHand.goto({name, type, params})
            },
            gotoMistakes(){
                let name = 'mistakes'
                let type = 'push'
                // this._routeHand.goto({name, type})
                this._routeHand.goto({
                    name,
                    type,
                    // query: {backName: 'task'}
                })
            },
            eventSet(){
                this.eventOff()
                this._common.eventSet('taskResultBackFun', e => {
                    this.back()
                })
            },
            eventOff(){
                this._common.eventRemove('taskResultBackFun')
            },
            back(){
                this._routeHand.back()
            },
        },
        created() {
            // this.eventSet()
        },
        components: {
        },
        computed: {
            fullMark(){
                return this.wrongNum == 0 && this.correctNum > 0
            },
            funListCul(){
                let {funList: list} = this
                let data = this._callFun.funhandAct.call(this, list)
                return data
            },
            correctNum(){
                let {chapterAnswers: list} = this
                let num = 0
                for(let i in list){
                    if(list[i].correct) num++
                }
                return num
            },
            wrongNum(){
                let {chapterAnswers: list} = this
                let num = 0
                for(let i in list){
                    if(!list[i].correct) num++
                }
                return num
            },
            chapterInfo(){
                let {chapterData: {townCourseChapter = {}} = {}} = this
                return townCourseChapter
            },
            chapterAnswers(){
                let {chapterData: {townUserCourseAnswer = {}} = {}} = this
                return townUserCourseAnswer
            },
            chapterNo(){
                let {chapterInfo: {chapterNo}} = this
                return chapterNo
            },
            chapterName(){
                let {chapterInfo: {chapterName}} = this
                return chapterName
            },
            usercode(){
                return this._userHand.usercodeGet()
            }
        },
        watch: {
            // $route: {
            //     handler(){
            //         this.chapterId = this.$route.params.chapterId
            //     },
            //     deep: true,
            //     immediate: true
            // },
            // chapterId: {
            //     handler(val){
            //         if(!val) return
            //         this.dataLoad()
            //     },
            //     deep: true,
            //     immediate: true
            // }
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.eventOff()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' src="./resultCheck.less">
    
</style>