<!--  -->
<template>
    <div class='page-task-task-summary flex-center'>
        <component-bcg url="task/c1.png"></component-bcg>
        <div class="bcg">
            <div class="board">
                <div class="task-room-bcg">
                    <div class="task-room flex-center" >
                        <span class="text-large-c text-purple-color bold room">{{pageText.t1}}</span>
                    </div>
                </div>
                <div class="page-title-box">
                    <div class="title-item scale-button" 
                    v-for="(v, i) in tagTypeListCul" :key="v.value" 
                    :class="[v.class, `item-${i}`, {'active': v.value == tagTypeVal}]" 
                    @click="changeTagType(v)">
                        <div class="body">
                            <div class="item-sel-label" v-if="v.value == tagTypeVal"></div>
                            <span class="bold flex text" :class="{'text-normal': v.value == tagTypeVal, 'text-normal-aa': v.value != tagTypeVal}">{{v.title}}</span>
                        </div>
                    </div>
                </div>
                <div class="task-head-bcg" v-if="!isTopic">
                    <div class="task-rack">
                        <div class="task-left" @click="prev()"></div>
                        <div class="task-item-body mini-scrollbar-x" ref="headScroBox">
                            <div class="items" :style="{'width': `${headItemWidth}%`}" ref="headScroContent">
                                <div class="item flex-center" 
                                :ref="`headItem${i}`"
                                :style="{'width': `${headItemWidthSize}%`}"
                                :class="[{'one':i == 0},{'active': v.value == headVal}]"
                                v-for="(v, i) in headListCul" :key="i" 
                                @click="levelChange(v)">
                                    <span class="txt">{{v.label}}</span>
                                </div>
                            </div>
                        </div>
                        
                        <div class="task-right" @click="next()"></div>
                    </div>
                </div>
                <div class="task-head-bcg" v-else>
                    <div class="task-rack">
                        <div class="task-left" @click="prev()"></div>
                        <div class="task-item-body mini-scrollbar-x" ref="headScroBox">
                            <div class="items" :style="{'width': `${headItemWidth}%`}" ref="headScroContent">
                                <div class="item topic flex-center" 
                                :style="{'width': `${headItemWidthSize}%`}"
                                :class="[{'one':i==0},{'active':v.value==headVal},{'disabled': v.disabled}]"
                                v-for="(v,i) in headListCul" :key="i" 
                                @click="subjectChange(v)">
                                    <span class="txt">{{v.label}}</span>
                                </div>
                            </div>
                        </div>
                        
                        <div class="task-right" @click="next()"></div>
                    </div>
                </div>
                <div class="task-content-bcg">
                    <div class="task-content" v-if="!loading && !courseCount && loadingFlag">
                        <div class="items flex-center max-size ">
                            <span class="text-normal text-yel-color bold">{{noneCourseText}}</span>
                        </div>
                    </div>
                    <div class="task-content" v-else>
                        <div class="head" v-if="courseCount&&!isTopic">
                            <div class="c11"></div>
                            <span class="head-title bold">{{pageText.t4}}{{availableCount}}/{{courseCount}}{{pageText.t5}}</span>
                        </div>
                        <div class="head practice" v-if="courseCount&&isTopic">
                            <!-- <div class="c11"></div>
                            <span class="head-title bold">{{pageText.t4}}{{availableCount}}/{{courseCount}}{{pageText.t5}}</span> -->
                            <div class="practice-all">
                                <span class="bold t">{{'综合练习'}}</span>
                                <!-- <span class="bold t" @click="gotoSections()">去答题</span> -->
                                <div class="btn" @click="gotoSections()">
                                    <confirmPlay :type="`normal`" :disabled="false">
                                        <div class="flex-center">
                                            <span class="text-normal-c bold btn-txt">{{'去答题'}}</span>  
                                        </div>
                                    </confirmPlay>
                                </div>
                            </div>
                        </div>
                        <div class="items mini-scrollbar" id="task-content-items">
                            <div ref="commentBox">
                                <div class="item" v-for="v in chapterListCul" :key="v.chapterId" :id="`item_${v.chapterId}`">
                                    <div class="option" v-if="!isTopic">
                                        <div class="title">
                                            <span class="text-normal-c bold t course-name-txt hide-point-txt">{{v.courseName}}</span>
                                            <span v-if="v.status" class="text-mini bold b" :class="{mark:v.winRate}">正确题目 {{v.correctCount}}/{{v.exerciseCount}}</span>
                                            <span v-else class="text-mini bold b"></span>
                                        </div>
                                        <div class="btn-bcg-r flex-center" @click="handAction(v)">
                                            <div class="btn">
                                                <confirmPlay :type="v.type" :disabled="v.disabled">
                                                    <div class="flex-center">
                                                        <span class="text-normal-c bold btn-txt">{{v.statusText}}</span>  
                                                    </div>
                                                </confirmPlay>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="option topic" v-else>
                                        <div class="title">
                                            <span class="text-normal-c bold t">{{v.chapterName}}</span>
                                        </div>
                                        <div class="btn-bcg-r flex-center" @click="gotoSection(v)">
                                            <div class="btn">
                                                <confirmPlay :type="v.type" :disabled="v.disabled">
                                                    <div class="flex-center">
                                                        <span class="text-normal-c bold btn-txt">{{'去答题'}}</span>  
                                                    </div>
                                                </confirmPlay>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <chapterResult :chapterId.sync="chapterId" ref="chapterResult"></chapterResult>
    </div>
</template>

<script>
    import apiClass from '@/API/page/task'
    import ComponentBcg from '../../../components/componentBcg.vue'
    import chapterResult from './resultCheck.vue'
    import stone from '@/components/stone.vue'
    export default {
        name: '',
        props: {
        },
        data() {
            return {
                api: new apiClass(),
                headVal: this.$route.params.headVal,
                subject: this.$route.params.subject,
                hash: this.$route.hash,
                hashState: false,
                // headList: [],
                headListCourse: [],
                tagTypeList: [
                    {title: '我的作业', value: 1, class:'homework', state: true, disabledCheck: 'courseDisabled'},
                    {title: '专项题库', value: 2, class:'topic', state: true, disabledCheck: 'subjectDisabled'}
                ],
                // tagTypeVal: 1,
                tagTypeVal: this.$route.query.tagTypeVal,
                loading: false,
                chapterList: [],
                courseAuth: null,
                stepInfo: {},
                chapterId: null,
                show:false,
                subChapterList: [],
                subHeadList: [
                    // { label: 'G1', value: '2', disabled: false },
                    // { label: 'G2', value: '3', disabled: false },
                ],
                defaultCourseId: '2',
                loadingFlag: false,
                headNum: 4
            };
        },
        methods: {
            async pageInit(){
                // this.headList = []
                this.chapterList = []
                this.subChapterList = []
                // if(val) return this.configLoad(val)
                await this.configLoad()
                this.changeTagTypeInit()
            },
            changeTagTypeInit(){
                if(this.tagTypeSelected.value && !this.tagTypeSelected.disabled) return
                let {value} = this.tagTypeListDefault || {}
                if(!value) return
                this.changeTagType(this.tagTypeListDefault)
            },
            async changeTagType(val){
                let {value, disabled} = val || {}
                if(this.tagTypeVal == value || !value || disabled) return
                await this.refreshPage({query: {tagTypeVal: value}})
            },
            async configLoad(val){
                return Promise.all([
                    this.configLoadCourse(),
                    this.configLoadSubject()
                ])
            },
            async configLoadCourse(){
                let {headListCourse} = this
                if(headListCourse && headListCourse.length) return
                // let res = await this.api.courseListCo()
                let res = await this.api.courseListCov()
                if(res){
                    // let {content} = res
                    let content = res
                    for(let i in content){
                        let {courseBriefIntro} = content[i]
                        courseBriefIntro = courseBriefIntro || ''
                        try{
                            courseBriefIntro = JSON.parse(courseBriefIntro)
                        }catch(e){ courseBriefIntro = {} }
                        content[i].courseBriefIntro = courseBriefIntro
                    }
                    this.headListCourse = content
                }
            },
            async configLoadSubject(){
                let res = await this.api.configLoad('subject')
                if(!res) return
                this.subHeadList = res
            },
            async headValCheck(){
                // let {selectStep: {value} = {}} = this
                if(this.headSelected.value) return
                let {value} = this.headListCul[0] || {}
                if(!value) return
                let params = {headVal: value}
                this.refreshPage({params})
            },
            async prev(){
                let {selectStep: {value} = {}, headListCul:list, prevValue} = this
                if(prevValue < 0) return this.refreshPage({params: {headVal: value}})
                value = list[prevValue].value
                this.refreshPage({params: {headVal: value}})
                // if(this.tagTypeVal == 2) this.subjectChapterList(value)
            },
            async next(){
                let {selectStep: {value} = {}, headListCul:list, nextValue} = this
                if(nextValue >= list.length) return this.refreshPage({params: {headVal: value}})
                value = list[nextValue].value
                this.refreshPage({params: {headVal: value}})
                // if(this.tagTypeVal == 2) this.subjectChapterList(value)
            },
            async refreshPage(opt){
                let {params, query, hash} = opt || {}
                params = params || {}
                query = query || {}
                let name = 'task-summary'
                let type = 'replace'
                
                params = {
                    ...this.$route.params,
                    ...params
                }
                query = {
                    ...this.$route.query,
                    ...query
                }
                this._routeHand.goto({name, type, params, hash, query})
                await this._common.nextTick.call(this)
                return true
            },
            async levelChange(val){
                if(val.disabled) return
                this.refreshPage({params: {headVal: val.value}})
            },
            async subjectChange(val){
                let {selectStep: {value} = {}, headListCul:list, nextValue} = this
                if(val.disabled || val.value == value) return
                this.refreshPage({params: {headVal: val.value}})
                // this.subjectChapterList(val.value)
            },
            dataLoad(){
                if(this.isTopic) return this.subjectChapterList()
                return this.courseListInit()
            },
            async courseListInit(){
                this.loadingFlag = false
                this.chapterList = []
                let res = await this.courseAuthCheck()
                if(!res) return
                let {courseId: course_id, usercode: user_code} = this
                res = await this.api.chapterList({course_id, user_code})
                if(res) this.chapterList = res
                this.loadingFlag = true
                this.hashHand()
                return res
            },
            async courseAuthCheck(){
                let {courseId: course_id,usercode: user_code} = this
                if(!course_id) return
                this.courseAuth = null
                let res = await this.api.courseAuth({course_id,user_code})
                this.courseAuth = res ? true : false
                return res
            },
            async stepInfoLoad(){
                let {selectStep: {courseId: course_id}} = this
                if(this.isSubject) return
                let res = await this.api.courseProgress({course_id})
                if(res) this.stepInfo = res || {}
            },
            async hashHand(){
                let {hash, hashState} = this
                if(!hash || hashState) return
                await this._common.nextTick.call(this)
                this.hashState = true
                let box = document.querySelector('#task-content-items')
                let ele = document.querySelector(hash)
                if(!box || !ele) return
                box.scrollTop = ele.offsetTop - ele.offsetHeight
            },
            statusTextGet(status){
                let text = [
                    '',
                    '查看',
                    '去答题',
                    '',
                    '未解锁'
                ]
                return text[status] || ''
            },
            typeGet(status){
                let text = [
                    '',
                    'primary',
                    'normal',
                    '',
                    'disabled',
                ]
                return text[status] || ''
            },
            chapterStatusGet(status){
                let rd = false 
                if(status==1) rd = true
                return rd
            },
            disabledGet(status){
                let text = {
                    '4': true
                }
                return text[status] || false
            },
            gotoSection(opt){
                let name = 'task-subject-chapter'
                let type = 'replace'
                let params = {
                    courseId: this.headVal,
                    chapterNo: opt.chapterNo,
                    chapterName: opt.chapterName,
                }
                params = {
                    routeData: this._common.encodeData(params)
                }
                let data = {name, params}
                if(this.isSubject) this._routeHand.goto(data)
            },
            gotoSections(){
                let name = 'task-subject-sections'
                let type = 'replace'
                let params = {
                    courseId: this.headVal,
                    // chapterNo: opt.chapterNo,
                    // chapterName: opt.chapterName,

                    // courseId: routeDataCul.courseId,
                    // chapterNo: routeDataCul.chapterNo,
                    // chapterName: routeDataCul.chapterName,
                    // sectionNo: opt.sectionNo,
                    // sectionName: opt.sectionName,
                    // id: opt.id,
                }
                params = {
                    routeData: this._common.encodeData(params)
                }
                this._routeHand.goto({ name, type, params })
            },
            handAction(val){
                let {chapterId, disabled, chapterStatus} = val || {}
                if(disabled || !chapterId) return
                if(chapterStatus == 1) return this.resultOpen(chapterId)
                if(chapterStatus == 2) {
                    let data = this.gotoParamsGet(chapterStatus, chapterId)
                    if(!data) return
                    this._routeHand.goto(data)
                }
                // if(chapterStatus == 1){
                //     this.show = true
                //     this.chapterId = chapterId
                // } 
            },
            resultOpen(chapterId){
                this.$refs['chapterResult'].open({chapterId})
            },
            gotoParamsGet(status, chapterId){
                let list = {
                    '1': {
                        name: 'task-result',
                        params: {chapterId}
                    },
                    '2': {
                        name: 'task-action',
                        params: {routeData: this._common.encodeData({chapterId})}
                    }
                }
                let query = this.$route.query
                return {...list[status], query}
            },
            rateGet(a,b){
                let status = false
                let res = (a/b)*100
                if(res < 90) status = true
                return status
            },
            async subjectChapterList(){
                this.loadingFlag = false
                let {value: course_id} = this.headSelected
                if(!course_id) return
                let res = await this.api.subjectChapterList({course_id})
                if(res) this.subChapterList = res
                this.loadingFlag = true
            },
            async headPostionCheck(){
                await this._common.nextTick.call(this)
                let box = this.$refs.headScroBox
                let con = this.$refs.headScroContent
                let item = document.querySelector('.page-task-task-summary .bcg .board .task-head-bcg .task-rack .items .item')
                if(!box || !con || !item) return
                let dis = box.scrollLeft.toFixed(0)
                let itemWidth = item.offsetWidth.toFixed(0)
                let disWidth = this.headNum * itemWidth
                let {index} = this.headSelected
                let k = Number(index) + 1
                let start = (Number(index) + 1) * itemWidth - disWidth
                let end = index * itemWidth
                // if(dis >= start && dis <= end) return
                let rd = null
                if(dis < start) rd = start
                else if(dis > end) rd = end
                if(rd === null) return
                if(rd < 0) rd = 0
                // box.scrollLeft = index * itemWidth
                box.scrollLeft = rd
                



            }
        },
        created() {
            this.pageInit()
        },
        components: {
            ComponentBcg,
            chapterResult,
        },
        computed: {
            headItemWidth(){
                let length = this.headListCul.length
                let num = this.headNum
                return ((100 / num).toFixed(2)) * length
            },
            headItemWidthSize(){
                let length = this.headListCul.length
                return `${(100 / length)}`
            },
            courseDisabled(){
                let {headListCourse: list} = this
                return !list.length
            },
            isSubject(){
                return this.tagTypeVal == 2
            },
            tagTypeListDefault(){
                let {tagTypeListCul: list} = this
                let data = null
                for(let i in list){
                    if(!list[i].disabled){
                        data = list[i]
                        break
                    }
                }
                return data
            },
            tagTypeListCul(){
                let list = this._common.deepCopy(this.tagTypeList)
                let data = []
                for(let i in list){
                    let {disabledCheck} = list[i]
                    // if(this[disabledCheck] !== undefined) list[i].disabled = this[disabledCheck]
                    if(this[disabledCheck] !== undefined && this[disabledCheck]) continue
                    data.push(list[i])

                }
                return data
            },
            tagTypeSelected(){
                let {tagTypeListCul: list,tagTypeVal: value} = this
                let key = 'value'
                let {data} = this._common.selected({list, value, key})
                return data || {}
                // let rd = {}
                // for(let i in list){
                //     if(list[i].value==val){
                //         rd = list[i].value
                //         break
                //     }
                // }
                // return rd
            },
            disabled(){
                let {tagTypeListCul:list} = this
                let rd = false
                for(let i in list){
                    if(!list[i].state){
                        rd = true
                        break
                    }
                }
                return rd
            },
            noAuth(){
                if(this.courseAuth === null) return false
                return !this.courseAuth
            },
            noneCourseText(){
                if(this.noAuth) return this.noAuthText
                let text = this.pageText.t3
                let {label} = this.selectStep || {}
                label = label || ''
                return `${label}${text}`
            },
            noAuthText(){
                return this.pageText.t2
            },
            availableCount(){
                return this.availableCourse.length
            },
            availableCourse(){
                let {chapterListCul: list} = this
                let rd = []
                for (const i in list) {
                    if(list[i].chapterStatus == 1) rd.push(list[i])
                }
                return rd
            },
            courseCount(){
                return this.chapterListCul.length
            },
            courseId(){
                // let {stepInfo: {id} = {}} = this
                // return id
                return this.headSelected.courseId
            },
            chapterListCul(){
                let {chapterList: list, subChapterList } = this
                if(this.isSubject && subChapterList != []){
                    list = this._common.deepCopy(subChapterList)
                    for (const i in list) {
                    let {chapterNo} = list[i]
                        list[i].chapterId = chapterNo
                    }
                    return list
                }
                list = this._common.deepCopy(list)
                let rd = []
                for(let i in list){
                    let {startTime: {date = {}, time = {}} = {}, chapterStatus,correctCount,exerciseCount} = list[i]
                    if(chapterStatus != 1 && chapterStatus != 2 && chapterStatus != 4) chapterStatus = 1
                    list[i].chapterStatus = chapterStatus
                    let date_s = this._common.serverTimeFormat(date)
                    // let time_s = this._common.serverTimeFormat(time)
                    let t = `${date_s.year}.${date.month}.${date.day}`
                    list[i].time = t
                    list[i].statusText = this.statusTextGet(chapterStatus)
                    list[i].disabled = this.disabledGet(chapterStatus)
                    list[i].correctCount = Number(correctCount)
                    list[i].exerciseCount = Number(exerciseCount)
                    list[i].winRate = this.rateGet(correctCount,exerciseCount)
                    list[i].type = this.typeGet(chapterStatus)
                    list[i].status = this.chapterStatusGet(chapterStatus)
                    rd.push(list[i])
                }
                return rd
            },
            headSelected(){
                let {headListCul: list, headVal: value} = this
                let key = 'value'
                let {data} = this._common.selected({list, value, key})
                return data || {}
            },
            headList(){
                if(!this.isTopic) return this.headListCourse
                return this.subHeadList
            },
            headListCul(){
                // let {headList: list, tagTypeVal, subHeadList} = this
                // if(tagTypeVal == 2) return subHeadList

                // let {headList: list} = this
                // list = this._common.deepCopy(list)
                // for(let i in list){
                //     let {resourceDetail = {}} = list[i]
                //     list[i] = {...list[i], ...resourceDetail}
                // }
                // return list


                let {headList: list} = this
                list = this._common.deepCopy(list)
                for(let i in list){
                    let {id, courseName, resourceDetail = {}, courseBriefIntro = {}} = list[i]
                    if(courseName){
                        list[i].value = id
                        list[i].label = courseName
                        list[i].courseId = id
                    }
                    list[i] = {...list[i], ...resourceDetail, ...courseBriefIntro, index: i}
                    
                }
                return list
            },
            prevValue(){
                let {selectStep: {value} = {}, headListCul:list} = this
                let rd = null
                for (const i in list) {
                    if(list[i].value == value) {
                        rd = parseInt(i) - 1
                        break
                    }
                }
                return rd
            },
            nextValue(){
                let {selectStep: {value} = {}, headListCul:list} = this
                let rd = null
                for (const i in list) {
                    if(list[i].value == value) {
                        rd = parseInt(i) + 1
                        break
                    }
                }
                return rd
            },
            usercode(){
                let {
                    app: {usercode} = {}
                } = this.$store.state
                return usercode
            },
            selectStep(){
                let {headListCul: list, headVal} = this
                let rd = {}
                for (const i in list) {
                    let {value} = list[i]
                    if(value == headVal){
                        rd = list[i]
                        break
                    }
                }
                return rd
            },
            isTopic(){
                let rd = false
                if(this.isSubject) rd = true
                return rd
            },
            headValCheckWatch(){
                let {headListCul: list, tagTypeVal: value} = this
                return {list, value}
            }
        },
        watch: {
            headValCheckWatch: {
                handler(val, oldVal){
                    if(this._common.isSameObject(val, oldVal)) return
                    this.headValCheck()
                },
                deep: true,
                immediate: true
            },
            selectStep: {
                handler(val){
                    if(!val || !val.courseId) return
                    this.stepInfoLoad()
                },
                deep: true,
                immediate: true
            },
            // courseId: {
            //     async handler(){
            //         this.loading = true
            //         await this.courseListInit()
            //         this.loading = false
            //     },
            //     deep: true,
            //     immediate:true
            // },
            headSelected: {
                handler(val, oldVal){
                    if(this._common.isSameObject(val, oldVal)) return
                    this.dataLoad()
                    this.headPostionCheck()
                },
                deep: true,
                immediate: true
            },
            $route:{
                handler(){
                    this.headVal = this.$route.params.headVal
                    this.hash = this.$route.hash
                    this.tagTypeVal = this.$route.query.tagTypeVal
                },
                deep: true,
                immediate: true
            },
            subject: {
                handler(val){
                    if(!val) return
                    // this.changeTagType(2)
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less'>
    @text-yel-color:#8D7461;
    @text-purple-color:#59578E;
    @text-red-color:#991B3D;
    @text-pur-color:#6143AF;
    .text-pur-color{
        color: @text-pur-color;
    }
    .text-red-color{
        color: @text-red-color;
    }
    .text-yel-color{
        color: @text-yel-color;
    }
    .text-purple-color{
        color: @text-purple-color;
    }
    .chapter-result-box{
        position: relative;
        width: 285px;
        height: 323px;
    }
    .page-task-task-summary{
        > .bcg{
            .background-img-max(url('@{assetsUrlV2_0}/task/c2.png'));
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 638px;
            height: 375px;
            .board{
                .background-img-max(url('@{assetsUrlV2_0}/task/c3.png'));
                width: 621px;
                height: 360px;
                position: absolute;
                top: 10px;
                left: 10px;
                .task-room-bcg{
                    .background-img-max(url('@{assetsUrlV2_0}/task/c4.png'));
                    width: 209px;
                    height: 43px;
                    position: absolute;
                    top: 2px;
                    left: 206px;
                    .task-room{
                        .background-img-max(url('@{assetsUrlV2_0}/task/c5.png'));
                        width: 193px;
                        height: 43px;
                        position: absolute;
                        top: -11px;
                        left: 7px;
                        .room{
                            position: relative;
                            top: -1px;
                        }
                    }
                }
                
                .page-title-box{
                    position: absolute;
                    left: 32px;
                    top: 21px;
                    display: flex;
                    .title-item{
                        position: relative;
                        .body{
                            position: relative;
                            .item-sel-label{
                                z-index: 0;
                                position: absolute;
                            }
                            .text{
                                position: absolute;
                                z-index: 1;
                            }
                        }
                    }
                    .title-item.active{
                        margin-top: -5px;
                        z-index: 2;
                    }
                    .item-0{
                        
                    }
                    .item-0.active{
                        margin-left: -2px;
                    }
                    .item-1{
                        margin-left: -27px;
                        margin-top: 1px;
                        
                    }
                    .item-1.active{
                        margin-left: -30px;
                    }
                    .homework{
                        // .background-img-max(url('@{assetsUrlV2_0}/task/c17.png'));
                        padding: 4px 0px 0px 5px;
                        width: 95px;
                        height: 36px;
                        .body{
                            padding: 8px 0px 0px 12px;
                            color: #991B3D;
                            width: 87px;
                            height: 29px;
                            .background-img-max(url('@{assetsUrlV2_0}/task/c18_0.png'));
                            .text{
                                top: 8px;
                                left: 12px;
                            }
                        }
                        .item-sel-label{
                            top: 5px;
                            left: 5px;
                            width: 84px;
                            height: 24px;
                            .background-img-max(url('@{assetsUrlV2_0}/task/c18_light.png'));
                        }
                    }
                    .homework.active{
                        width: 110px;
                        height: 41px;
                        .body{
                            padding: 10px 0px 0px 14px;
                            .background-img-max(url('@{assetsUrlV2_0}/task/c18.png'));
                            width: 102px;
                            height: 34px;
                            .text{
                                top: 10px;
                                left: 14px;
                            }
                        }
                    }
                    .topic.active{
                        width: 110px;
                        height: 41px;
                        .body{
                            .background-img-max(url('@{assetsUrlV2_0}/task/c19_0.png'));
                            width: 103px;
                            height: 34px;   
                            .text{
                                top: 10px;
                                left: 23px;
                            }
                        }
                    }
                    .topic{
                        // .background-img-max(url('@{assetsUrlV2_0}/task/c17.png'));
                        padding: 3px 0px 0px 4px;
                        width: 95px;
                        height: 36px;
                        .item-sel-label{
                            width: 78px;
                            height: 24px;
                            top: 5px;
                            left: 11px;
                            .background-img-max(url('@{assetsUrlV2_0}/task/c19_light.png'));
                        }
                        .body{
                            padding: 8px 0px 0px 20px;
                            color: #6143AF;
                            width: 89px;
                            height: 29px;
                            .background-img-max(url('@{assetsUrlV2_0}/task/c19.png'));
                            
                            .text{
                                top: 8px;
                                left: 20px;
                            }
                        }
                    }
                }




                .task-label{
                    position: absolute;
                    left: 33px;
                    bottom: 305px;
                    z-index: 2;
                    .label-item{
                        position: absolute;
                        bottom: -1px;
                    }
                    .item-1{
                        left: 0;
                    }
                    .item-1.on{}
                    .item-2{
                        left: 83px;
                        z-index: -1;
                    }
                    .item-2.on{}
                    .homework{
                        .background-img-max(url('@{assetsUrlV2_0}/task/c17.png'));
                        width: 95px;
                        height: 36px;
                        // position: absolute;
                        left: 0;
                        bottom: -1px;
                        .c_18{
                            cursor: pointer;
                            .background-img-max(url('@{assetsUrlV2_0}/task/c18_0.png'));
                            width: 89px;
                            height: 29px;
                            position: absolute;
                            bottom: 4px;
                            left: 4px;
                            .txt{
                                font-size: 12px;
                                margin-left: -10px;
                                color: @text-red-color;
                            }
                        }
                    }
                    .homework.on{
                        width: 109px;
                        height: 42px;
                        bottom: -2px;
                        .c_18{
                            .background-img-max(url('@{assetsUrlV2_0}/task/c18.png'));
                            width: 102px;
                            height: 34px;
                            .c_18_light{
                                .background-img-max(url('@{assetsUrlV2_0}/task/c18_light.png'));
                                width: 84px;
                                height: 24px;
                                position: absolute;
                                left: 5px;
                                top: 5px;
                                .txt{
                                    font-size: 15px;
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                    .topic{
                        .background-img-max(url('@{assetsUrlV2_0}/task/c17.png'));
                        width: 95px;
                        height: 36px;
                        // position: absolute;
                        left: 83px;
                        z-index: -1;
                        bottom: -1px;
                        .c_19{
                            cursor: pointer;
                            .background-img-max(url('@{assetsUrlV2_0}/task/c19.png'));
                            width: 89px;
                            height: 29px;
                            position: absolute;
                            bottom: 4px;
                            left: 3px;
                            .txt{
                                font-size: 12px;
                                color: @text-pur-color;
                            }
                        }
                        .c_19.not{
                            .background-img-max(url('@{assetsUrlV2_0}/task/c19_1.png'));
                        }
                    }
                    .topic-bcg{
                        .background-img-max(url('@{assetsUrlV2_0}/task/c17.png'));
                        width: 109px;
                        height: 42px;
                        position: absolute;
                        left: 65px;
                        bottom: -1px;
                        z-index: -1;
                    }
                    .topic.on{
                        background: none;
                        width: 109px;
                        height: 42px;
                        left: 65px;
                        z-index: 1;
                        .c_19{
                            .background-img-max(url('@{assetsUrlV2_0}/task/c19_0.png'));
                            width: 102px;
                            height: 34px;
                             .c_19_light{
                                .background-img-max(url('@{assetsUrlV2_0}/task/c19_light.png'));
                                width: 78px;
                                height: 24px;
                                .txt{
                                    font-size: 14px;
                                }
                            }
                        }
                        .c_19.not{
                            .background-img-max(url('@{assetsUrlV2_0}/task/c19_1.png'));
                        }
                    }
                }
                .task-head-bcg{
                    z-index: 2;
                    .background-img-max(url('@{assetsUrlV2_0}/task/c8_0.png'));
                    width: 557px;
                    height: 44px;
                    position: absolute;
                    top: 49px;
                    left: 31px;
                    .task-rack{
                        z-index: 1;
                        width: 547px;
                        height: 36px;
                        position: absolute;
                        top: 4px;
                        left: 5px;
                        display: flex;
                        border-radius: 10px;
                        white-space: nowrap;
                        .task-item-body{
                        overflow-X: auto;
                            position: absolute;
                            left: 36px;
                            top: 0px;
                            width: calc(100% - 36px * 2)
                        }
                        .task-left{
                            .background-img-max(url('@{assetsUrlV2_0}/task/left.png'));
                            width: 36px;
                            height: 36px;
                            cursor: pointer;
                            position: absolute;
                            left: -1px;
                        }
                        .task-right{
                            .background-img-max(url('@{assetsUrlV2_0}/task/right.png'));
                            width: 36px;
                            height: 36px;
                            cursor: pointer;
                            position: absolute;
                            right: -1px;
                        }
                        .items{
                            // position: absolute;
                            // left: 36px;
                            // display: flex;
                            // justify-content: center;
                            justify-content: flex-start;
                            // width: 475px;
                            min-width: 100%;
                            overflow: hidden;
                            .item{
                                float: left;
                                cursor: pointer;
                                .background-img-max(url('@{assetsUrlV2_0}/task/c20.png'));
                                // width: 160px;
                                width: 33.3%;
                                height: 36px;
                                margin-left: -1px;
                                .txt{
                                    font-size: 17px;
                                    height: 15px;
                                    line-height:15px;
                                    text-align: center;
                                    display: inline-block;
                                    position: absolute;
                                    top: 10px;
                                    color: #8F7CA7;
                                    font-weight: bold;
                                }
                            }
                            // .item.one{
                            //     width: 120px;
                            //     height: 58px;
                            //     .txt{
                            //         position: absolute;
                            //         top: 12px;
                            //         font-weight: bold;
                            //     }
                            // }
                            .item.active{
                                .background-img-max(url('@{assetsUrlV2_0}/task/c20_0.png'));
                                width: 159px;
                                z-index: 1;
                                .txt{
                                    color:#7964AE;
                                    font-size: 17px;
                                    height: 17px;
                                    line-height:17px;
                                    text-align: center;
                                    display: inline-block;
                                }
                            }
                            .item.topic{
                                width: 240px;
                            }
                            .item.topic.disabled{
                                .background-img-max(url('@{assetsUrlV2_0}/task/c20_0.png'));
                                filter: grayscale(100%);
                            }
                            .item.topic.active{
                                width: 239px;;
                            }
                        }
                    }
                }
                .task-content-bcg{
                    .background-img-max(url('@{assetsUrlV2_0}/task/c6_0.png'));
                    width: 579px;
                    height: 267px;
                    position: absolute;
                    top: 96px;
                    left: 20px;
                    .task-content{
                        .background-img-max(url('@{assetsUrlV2_0}/task/c7_0.png'));
                        width: 560px;
                        height: 257px;
                        position: absolute;
                        top: 0px;
                        left: 12px;
                        .head.practice{
                            top: 8px;
                            left: 31px;
                            .practice-all{
                                width: 491px;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                    padding: 0 5px 0 18px;
                                .btn{
                                    width: 81px;
                                    height: 25px;
                                    .btn-txt{
                                        height: 25px;
                                        line-height: 25px;
                                        font-size: 14px;
                                    }
                                }
                                .t{
                                    color: #8D7461;
                                }
                                .com-confirm-button{
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                        .head{
                            position: absolute;
                            top: 14px;
                            left: 31px;
                            display: flex;
                            .c11{
                                .background-img-max(url('@{assetsUrlV2_0}/task/c11.png'));
                                width: 5px;
                                height: 12px;
                            }
                            .head-title{
                                font-size: 12px;
                                height: 12px;
                                line-height: 12px;
                                text-align: center;
                                display: inline-block;
                                color: #B69983;
                                transform: scale(0.9);
                            }
                        }
                        .items{
                            width: 510px;
                            height: 200px;
                            position: absolute;
                            top: 39px;
                            left: 26px;
                            padding-top: 5px;
                            .item{
                                .background-img-max(url('@{assetsUrlV2_0}/task/c13.png'));
                                width: 504px;
                                height: 49px;
                                position: relative;
                                .option{
                                    .background-img-max(url('@{assetsUrlV2_0}/task/c14.png'));
                                    width: 491px;
                                    height: 42px;
                                    position: absolute;
                                    top: 1px;
                                    left: 7px;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    padding: 2px 5px 0 15px;
                                    .title{
                                        width: calc(100% - 90px);
                                        display: flex;
                                        flex-direction: column;
                                        .t{
                                            color: #8D7461;
                                            align-self: flex-start;
                                        }
                                        .b{
                                            color: #A28671;
                                            align-self: flex-start;
                                            transform: scale(0.9);
                                            transform-origin: left;
                                            margin-top: 3px;
                                        }
                                        .b.mark{
                                            color: #E6385E;
                                        }
                                        .course-name-txt{
                                            width: 100%;
                                            text-align: left;
                                            line-height: @normal-c-font-size;
                                        }
                                    }
                                    .btn-bcg-r{
                                        // .background-img-max(url('@{assetsUrlV2_0}/task/c15.png'));
                                        width: 87px;
                                        height: 37px;
                                        .btn{
                                            width: 81px;
                                            height: 32px;
                                            .btn-txt{
                                                height: 32px;
                                                line-height: 32px;
                                            }
                                        }
                                        .com-confirm-button{
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // /deep/.com-confirm-button{
    //     width: 100%;
    //     height: 100%;
    // }
    // 滚动条
    .mini-scrollbar::-webkit-scrollbar{
        width: 8px;
        // .background-img-max(url('@{assetsUrlV2_0}/avatar/c31.png'));
    }
    .mini-scrollbar-x::-webkit-scrollbar{
        height: 4px;
    }
    .mini-scrollbar::-webkit-scrollbar-thumb, .mini-scrollbar-x::-webkit-scrollbar-thumb{
        border-radius: 10px;
        // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        // background: rgba(0,0,0,0.2);
        .background-img-max(url('@{assetsUrlV2_0}/task/c12.png'));
        // background: #FFFAE6;
        // background: url('@{assetsUrlV2_0}/avatar/c30.png') no-repeat;
        // background-position: center;
        
    }
    .mini-scrollbar::-webkit-scrollbar-track-piece{
        // background: red;
        // .background-img-max(url('@{assetsUrlV2_0}/avatar/c31.png'));
    }
    .mini-scrollbar::-webkit-scrollbar-thumb::before{
        content: '';
        .background-img-max(url('@{assetsUrlV2_0}/task/c12.png'));
    }
    .mini-scrollbar::-webkit-scrollbar-track, .mini-scrollbar-x::-webkit-scrollbar-track{
        // -webkit-box-shadow: inset 0 0 5px #C7946A;
        border-radius: 10px;
        // background: rgba(178,123,85,0.1);
        // background: #B27B55;
        background-image: linear-gradient(to right, #D2B7A2, #DDCDB8, #D2B7A2);
        // .background-img-max(url('@{assetsUrlV2_0}/avatar/c31.png'));
    }
    .mini-scrollbar{
        overflow-y: auto;
        overflow-x: hidden;
    }
</style>